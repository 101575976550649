<template>
  <video ref="videoPlayer" class="video-js w-100" id="videoPlayer"
    data-setup='{ "audioOnlyMode": true, "playbackRates": [0.75, 1, 1.25] }'>
  </video>
</template>

<script>
import videojs from 'video.js'

export default {
  name: 'VideoPlayer',
  props: {
    ttsUrl: {
        type: String
      }
  },
  data () {
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          { src: this.ttsUrl,
            type: 'audio/mp3'
          }
        ]
      },
      isLoaded: false
    }
  },
  watch: {
    'ttsUrl': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal && (newVal !== oldVal && self.isLoaded)) {
          self.reload()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let self = this
      self.player = videojs(self.$refs.videoPlayer, self.videoOptions, () => {
        self.isLoaded = true
      })
    },
    reload () {
      let self = this
      self.player.pause()
      self.$refs.videoPlayer.setAttribute('src', self.ttsUrl)
      self.player.load()
      self.player.play()
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
<style lang="scss">
  .vjs-control-bar {
    background-color: #0E103E !important;
  }
</style>